import { get, ApiError } from 'aws-amplify/api';
import { gunzipSync } from 'react-zlib-js';

// Define the structure of the init parameter
interface APIGetInit {
  headers?: Record<string, string>;
  queryStringParameters?: Record<string, string | string[] | number | boolean>;
  [key: string]: any; // Allow other properties
}

export const API = {
  get: async <T = any,>(apiName: string, path: string, init: APIGetInit = {}): Promise<T> => {
    try {
      // Process queryStringParameters
      console.log(`init.queryStringParameters : ${init.queryStringParameters}`);
      const processedQueryParams: Record<string, string> = {};
      if (init.queryStringParameters) {
        for (const [key, value] of Object.entries(init.queryStringParameters)) {
          if (Array.isArray(value)) {
            processedQueryParams[key] = JSON.stringify(value);
          } else {
            processedQueryParams[key] = String(value);
          }
        }
      }
      console.log(`processedQueryParams : ${processedQueryParams}`);
      const restOperation = get({
        apiName,
        path,
        options: {
          headers: init.headers,
          queryParams: processedQueryParams,
        },
      });

      setTimeout(() => restOperation.cancel(), 28000);

      console.log(init.queryStringParameters as Record<string, string>);
      console.log(processedQueryParams);

      const response = await restOperation.response;

      // Check if the response is binary data
      const contentType = response.headers['content-type'];
      console.log(contentType);
      if (contentType && contentType.includes('application/octet-stream')) {
        // Return the binary data as a Buffer
        const buffer = Buffer.from(await response.body.blob().then((blob) => blob.arrayBuffer()));
        const data = JSON.parse(gunzipSync(buffer).toString('utf-8'));
        return data as T;
      } else {
        // For other types, return as JSON
        const data = await response.body.json();
        return data as T;
      }
    } catch (error) {
      if (error instanceof ApiError) {
        console.error('API.get ApiError:', error.message);
      } else {
        console.error('API.get Error:', error);
      }
      throw error;
    }
  },
};
