import React, { useState, useEffect } from 'react';
import { list, downloadData } from 'aws-amplify/storage';
import { ListAllWithPathOutput } from 'aws-amplify/storage';
import Loader from '@amzn/meridian/loader';

interface DRLogReaderProps {
  path: string;
  removeNumbers?: boolean;
  bytesRange?: { start: number; end: number };
}

const DRLogReader: React.FC<DRLogReaderProps> = ({ path, removeNumbers = true, bytesRange }) => {
  const [processedContent, setProcessedContent] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getS3ObjectContent = async (itemPath: string): Promise<string> => {
      try {
        const { body } = await downloadData({
          path: itemPath,
          options: {
            ...(bytesRange && { bytesRange }),
          },
        }).result;

        if (body instanceof Blob) {
          return await body.text();
        }
        return '';
      } catch (error) {
        console.error(`Error getting object content for ${itemPath}:`, error);
        throw error;
      }
    };

    const processContent = (allLines: string[]): string => {
      if (removeNumbers) {
        allLines = allLines.map((line) => line.replace(/^\d+\|/, ''));
      }
      return allLines.join('\n');
    };

    const processS3Content = async () => {
      if (!path) {
        setError('S3 path not provided');
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const listResult: ListAllWithPathOutput = await list({ path, options: { listAll: true } });
        let allLines: string[] = [];

        for (const item of listResult.items) {
          const content = await getS3ObjectContent(item.path);
          const fileLines = content.split('\n').map((line) => line.trim());
          allLines = allLines.concat(fileLines);
        }

        // Sort all lines together
        allLines.sort((a, b) => {
          const numA = parseInt(a.split('|')[0]);
          const numB = parseInt(b.split('|')[0]);
          return numA - numB;
        });

        const processedAllContent = processContent(allLines);
        setProcessedContent(processedAllContent);
      } catch (error) {
        console.error('Error processing S3 content:', error);
        setError('Error processing S3 content');
      } finally {
        setIsLoading(false);
      }
    };

    processS3Content();
  }, [path, removeNumbers, bytesRange]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <pre>{processedContent}</pre>
    </div>
  );
};

export default DRLogReader;
