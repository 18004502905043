// eslint-disable-next-line
import React, { useState, useEffect, useCallback } from 'react';
import { DateTime } from 'luxon';

import { API } from './API';

// components
//import { ErrorDisplay } from "./ErrorDisplay";
import Row from '@amzn/meridian/row';
import Box from '@amzn/meridian/box';
import Modal from '@amzn/meridian/modal';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import DatePicker from 'react-datepicker';
import Heading from '@amzn/meridian/heading';
import Button from '@amzn/meridian/button';
import Blink from './Blink';
//import Toggle from "@amzn/meridian/toggle";
import FileInput, { FileDetails } from '@amzn/meridian/file-input';
import type { AuthDetails } from './auth/midwayAuth';

// types

import moment from 'moment';
import MaterialTable from '@material-table/core';
import Loader from '@amzn/meridian/loader';

import { sendSQSTCalcBuilder } from './SendSQS';
import Checkbox from '@amzn/meridian/checkbox';
import { S3Helper } from './S3Helper';

export type File = {
  name: string;
  path: string;
  size: number | string;
  error: boolean;
  errorMessage: string;
};

type ManageCalculationsViewProps = {
  userInfo: AuthDetails;
  setPageTitle: Function;
  projectName: string;
  projectEnv: string;
  projectUserAccess?: string;
};

/**
 * @props:
 *      onBatchUploadDataFilesSubmit: function called when user submits files
 *      error: error message to display for nav BatchUploadData file submits
 *      display_results: whether or not we should display the results on the map, controlled by toggle
 *      setDisplayResults: function to set whether or not to display the results on the map
 */

export const ManageCalculationsView = (props: ManageCalculationsViewProps) => {
  useEffect(() => {
    props.setPageTitle(`Manage Calculations${props.projectEnv === 'Dev' ? ' - Beta' : ''}`);
    setMetricTableData([]);
    setMetricTableHeaders([]);
    setIsMetricTableLoaded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCalculations = async () => {
    var apiCallParam = {
      headers: {},
      queryStringParameters: {
        operation: 'View',
        userId: props.userInfo.username,
        projectName: props.projectName,
        projectEnv: props.projectEnv,
      },
    };
    const data = await API.get('FireboltWebsiteAPIs', '/managecalculations', apiCallParam);
    if (data) {
      setMetricTableHeaders(data.dataHeader);
      setMetricTableData(data.dataBody);
      setIsMetricTableLoaded(true);
    }

    setIsMetricTableLoaded(true);
  };

  useEffect(() => {
    fetchCalculations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projectName, props.projectEnv]);

  const [files, setFiles] = React.useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false as boolean);
  const [showFileNotFoundModal, setShowFileNotFoundModal] = useState(false as boolean);
  const onCloseFileNotFoundModal = useCallback(() => setShowFileNotFoundModal(false), []);
  const [calcFileAsOfDate, setCalcFileAsOfDate] = useState(DateTime.now());
  const [uploadStatus, setUploadStatus] = useState('' as string);
  const [uploadCompleted, setUploadCompleted] = useState(false as boolean);
  const [metricTableHeaders, setMetricTableHeaders] = useState<Object[]>([]);
  const [metricTableData, setMetricTableData] = useState<Object[]>([]);
  const [isMetricTableLoaded, setIsMetricTableLoaded] = useState<boolean>(false);
  const [projectResetMessage, setProjectResetMessage] = useState('' as string);
  const [isProjectResetInProgress, setIsProjectResetInProgress] = useState<boolean>(false);
  const [clearDimensionEntries, setClearDimensionEntries] = useState<boolean>(true);
  const [clearMetricsAndCalcs, setClearMetricsAndCalcs] = useState<boolean>(true);
  const [clearProjectData, setClearProjectData] = useState<boolean>(true);
  const [cancelRecalc, setCancelRecalc] = useState<boolean>(true);

  /**
   * function called when file(s) are attached to the FileInput, adds file to space
   * @param {File[]} acceptedFiles array of files that were uploaded
   */
  const handleFileAttached = (acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
    setUploadStatus('');
  };

  const setPickerDate = (inputDate: any) => {
    if (inputDate == null) {
      console.log('Null date');
      return;
    }

    setCalcFileAsOfDate(DateTime.fromJSDate(inputDate));

    /**
         * <Text>Perform Full Recalc From:</Text>
                                <Column width="20">
                                    <DatePicker selected={recalcStartDate?.toJSDate()}
                                        onChange={(date: any) => setPickerDate(date, "StartFullRecalc")}
                                    />
                                </Column>
         */
  };

  //Storage.configure({ region: s3Region, bucket: s3Bucket });

  /**
   * function called when user clicks the 'Submit' button for the BatchUploadData files
   * call props function with the files from state
   */
  const handleDownloadCalcSubmit = async () => {
    const s3FilePrefix = 'public/calculations/' + props.projectName + '/' + props.projectEnv + '/';
    try {
      var s3FileList = await S3Helper.list(s3FilePrefix);
      const calcFileToDownload: string | undefined = s3FileList
        .filter(
          (file) =>
            file.path.endsWith('.xlsx') &&
            file.lastModified &&
            DateTime.fromJSDate(file.lastModified).toFormat('yyyyMMddHHmm') <=
              calcFileAsOfDate.toFormat('yyyyMMddHHmm'),
        )
        .sort((a, b) => {
          if (a.lastModified && b.lastModified) {
            return (
              DateTime.fromJSDate(b.lastModified).toFormat('yyyyMMddHHmm') -
              DateTime.fromJSDate(a.lastModified).toFormat('yyyyMMddHHmm')
            );
          }
          return 0;
        })[0]?.path;

      if (calcFileToDownload) {
        console.log(calcFileToDownload);
        await S3Helper.downloadFromS3(
          calcFileToDownload,
          'FireBolt_' + props.projectName + '_Calculations_' + calcFileAsOfDate.toFormat('yyyyMMddHHmm') + '.xlsx',
        );
      } else {
        throw new Error('No matching file found');
      }
    } catch (error) {
      console.log('No file or error occurred:', error);
      setShowFileNotFoundModal(true);
    }
  };

  const handleStartFullRecalc = async () => {
    const messageBody = JSON.stringify({
      Source: 'FullRecalc',
      Project: props.projectName,
      ProjectEnv: props.projectEnv,
      // , StartDate: recalcStartDate
    });
    try {
      await sendSQSTCalcBuilder(props.projectName, props.projectEnv, messageBody);
      return;
    } catch (err) {
      console.log('Error Initiating Full Recalc');
    }
  };

  const handleFilesSubmit = async () => {
    // If the file is selected , only then run the code...
    if (files.length === 0) {
      setIsUploading(false);
      //setUploadStatus('No file found to upload');
    } else {
      setIsUploading(true);
      //setUploadStatus('Uploading file');
      //setUploadCompleted(false);
    }

    // This code is upload an excel file
    var fileName: string;
    for (const file of files) {
      fileName =
        'calculations/' +
        props.projectName +
        '/' +
        props.projectEnv +
        '/' +
        props.userInfo.username +
        moment().utc().format('YYYYMMDD_hhmmss_') +
        file['path'];

      try {
        const s3Path = await S3Helper.put('public/' + fileName, file, {
          contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        console.log('Uploaded file path:', s3Path);

        const myInit = {
          headers: {},
          queryStringParameters: {
            s3Key: fileName, // Using s3Path directly as it's equivalent to the previous s3Key["key"]
            operation: 'Upload',
            userId: props.userInfo.username,
            projectName: props.projectName,
            projectEnv: props.projectEnv,
          },
        };
        const apicallreurn = await API.get('FireboltWebsiteAPIs', '/managecalculations', myInit);
        console.log(JSON.stringify(apicallreurn));
        JSON.stringify(apicallreurn) === 'true'
          ? setUploadStatus('Uploaded & Updated Successfully')
          : setUploadStatus(JSON.stringify(apicallreurn));
        fetchCalculations();
      } catch (err) {
        console.log('Upload Failed:' + file['path'], err);
      }
    }

    setFiles([]);
    setIsUploading(false);
    //setUploadStatus('Uploading file completed. It may take upto ~10 minutes for Database to be updated.');
    setUploadCompleted(true);
  };

  const handleResetProject = async () => {
    setIsProjectResetInProgress(true);
    setProjectResetMessage('Reset In Progress ...');
    var apiCallParam = {
      headers: {},
      queryStringParameters: {
        operation: 'ResetProject',
        userId: props.userInfo.username,
        projectName: props.projectName,
        projectEnv: props.projectEnv,
        clearDimensionEntries: clearDimensionEntries,
        clearMetricsAndCalcs: clearMetricsAndCalcs,
        clearProjectData: clearProjectData,
        cancelRecalc: cancelRecalc,
      },
    };
    try {
      const data = await API.get('FireboltWebsiteAPIs', '/managecalculations', apiCallParam);
      if (data) {
        setProjectResetMessage(data.resetStatus);
      } else {
        setProjectResetMessage('Reset Failed');
      }
    } catch (err) {
      setProjectResetMessage('Reset Failed or timedout');
      console.log('Reset Failed or timedout', err);
    }
    setIsProjectResetInProgress(false);
  };

  return (
    <>
      <Modal open={showFileNotFoundModal} onClose={onCloseFileNotFoundModal}>
        <Text>No Calculation Files available Prior to selected time.</Text>
      </Modal>
      <Column spacingInset="200">
        <Box type="outline">
          <Row spacing="large" spacingInset="small">
            <Text>Download Latest Calculation as of:</Text>
            <Column width="20">
              <DatePicker
                selected={calcFileAsOfDate?.toJSDate()}
                onChange={(date: any) => setPickerDate(date)}
                showTimeSelect
                dateFormat="yyyy-MM-dd HH:mm"
                timeFormat="HH:mm"
                timeIntervals={60}
              />
            </Column>
            <Button size="small" onClick={handleDownloadCalcSubmit}>
              Download Calculation
            </Button>
          </Row>
        </Box>
        <Row width="100%" widths="fill">
          <Column>
            <Box type="outline" spacingInset="400" height="100%">
              <Heading level={5}>Select Calculation File:</Heading>
              <Column spacing="large">
                <FileInput
                  onFileAttached={handleFileAttached}
                  type="single"
                  accept=".xlsx"
                  disabled={props.projectUserAccess === 'User'}
                >
                  {files?.map((file) => {
                    return (
                      <FileDetails
                        error={file.error}
                        errorMessage={file.errorMessage}
                        file={file}
                        key={file.name}
                        onClickRemoveFile={() => {
                          const updatedFileArr = files.filter((fl) => fl.name !== file.name);
                          setFiles(updatedFileArr);
                        }}
                        uploadComplete={true}
                      />
                    );
                  })}
                </FileInput>

                <Row>
                  <Button
                    onClick={handleFilesSubmit}
                    disabled={!(files?.length > 0) || isUploading || props.projectUserAccess === 'User'}
                  >
                    Submit Calculation
                  </Button>
                </Row>
                <Row>{uploadCompleted ? <Text>{uploadStatus}</Text> : null}</Row>
              </Column>
            </Box>
            <Row width="100%" widths="fill">
              <Box>
                {isMetricTableLoaded ? (
                  <MaterialTable
                    style={{ zIndex: 1 }}
                    columns={metricTableHeaders}
                    data={metricTableData}
                    title="Current Metrics"
                    options={{
                      filtering: true,
                      paging: true,
                      pageSize: 8,
                      search: true,
                    }}
                  />
                ) : (
                  <Loader />
                )}
              </Box>
            </Row>
          </Column>
        </Row>
        <Row width="100%" widths="fill">
          <Box type="outline" spacingInset="400" height="100%">
            <Row spacing="large" spacingInset="small" widths={['50%', '50%']}>
              <Column alignmentHorizontal="start">
                <Button size="small" onClick={handleStartFullRecalc} disabled={props.projectUserAccess === 'User'}>
                  Submit Full Recalc Request
                </Button>
              </Column>

              {props.projectUserAccess === 'Admin' && props.projectEnv === 'Dev' ? (
                <Column alignmentHorizontal="end">
                  <Row>
                    <Column>
                      <Checkbox checked={clearDimensionEntries} onChange={setClearDimensionEntries}>
                        Clear Dimension Entries?
                      </Checkbox>
                    </Column>
                    <Column>
                      <Checkbox checked={clearMetricsAndCalcs} onChange={setClearMetricsAndCalcs}>
                        Clear Metrics and Calculation Entries?
                      </Checkbox>
                    </Column>
                    <Column>
                      <Checkbox checked={clearProjectData} onChange={setClearProjectData}>
                        Clear Input and Results Table?
                      </Checkbox>
                    </Column>
                    <Column>
                      <Checkbox checked={cancelRecalc} onChange={setCancelRecalc}>
                        Delete Current Recalc?
                      </Checkbox>
                    </Column>
                    <Column>
                      <Button
                        size="small"
                        onClick={handleResetProject}
                        disabled={
                          isProjectResetInProgress ||
                          !(clearDimensionEntries || clearMetricsAndCalcs || clearProjectData || cancelRecalc)
                        }
                      >
                        Reset Project
                      </Button>
                    </Column>
                    <Column>
                      {isProjectResetInProgress ? (
                        <Blink color="red" text="Reset In Progress .." fontSize="20">
                          Reset In Progress ..
                        </Blink>
                      ) : (
                        <Text>{projectResetMessage}</Text>
                      )}
                    </Column>
                  </Row>
                </Column>
              ) : null}
            </Row>
          </Box>
        </Row>
      </Column>
    </>
  );
};
