// eslint-disable-next-line
import React, { useState, useEffect } from 'react';

import { getCurrentCredentials } from './auth/fetchAuthSession';
import { Lambda } from 'aws-sdk';

// components
import Row from '@amzn/meridian/row';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Heading from '@amzn/meridian/heading';
import Button from '@amzn/meridian/button';
import Loader from '@amzn/meridian/loader';
import Expander from '@amzn/meridian/expander';
import Checkbox from '@amzn/meridian/checkbox';
import ControlGroup from '@amzn/meridian/control-group';
import { uploadData } from '@aws-amplify/storage';
import { API } from './API';

import FileInput, { FileDetails } from '@amzn/meridian/file-input';
import type { AuthDetails } from './auth/midwayAuth';

import moment from 'moment';
import { onCreateFireBoltStatusInfoAppSyncJobID } from '../graphql/custom';
import { generateClient } from 'aws-amplify/api';

import { encodeSpaces } from './CommonFunctions';
import DRLogReader from './DRLogReader';

const graphQLClient = generateClient();

export type File = {
  name: string;
  path: string;
  size: number | string;
  error: boolean;
  errorMessage: string;
};

type BatchUploadDataViewProps = {
  userInfo: AuthDetails;
  setPageTitle: Function;
  projectName: string;
  projectEnv: string;
  dataType: string;
  recalcInProgress?: boolean;
};

type PathS3KeySheets = {
  path: string;
  s3Key: string;
  sheets: string[];
};

type Upload = {
  db?: string;
  details?: any;
  downstreamJobs?: {
    Datanet?: Array<{ status: string; message: string }>;
    Cradle?: Array<{ status: string; message: string }>;
  };
};

type FileResult = {
  fileName: string;
  status: string;
  sheets?: Array<{ sheetName: string; status: string; details?: any; error?: string }>;
  successfulUploads?: Record<string, Upload[]>;
  missingTablesInConfig?: Record<string, string>;
  errors?: Record<string, any>;
};

const BatchUploadDataViewConfig = {
  InputData: {
    pageTitle: 'Upload Input Data',
    s3Folder: 'web-input-data/',
    operation: 'uploadData',
  },
  OverrideData: {
    pageTitle: 'Upload Override Data',
    s3Folder: 'web-override-data/',
    operation: 'uploadOverrideData',
  },
};

export const BatchUploadDataView = (props: BatchUploadDataViewProps) => {
  useEffect(() => {
    props.setPageTitle(
      `${BatchUploadDataViewConfig[props.dataType].pageTitle}${props.projectEnv === 'Dev' ? ' - Beta' : ''}`,
    );
    /*
    setFilePrefix(
      BatchUploadDataViewConfig[props.dataType].s3Folder +
        props.projectName +
        '/' +
        props.userInfo.username +
        moment().utc().format('YYYYMMDD_hhmmss_'),
    ); 
    */
    console.log(filePrefix);
    setFiles([]);
    setPathS3KeySheetsResponse([]);
    setSelectedS3FileSheet([]);
    setUploadResponse([]);
    setS3KeyToFileName({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataType, props.projectName]);

  const [files, setFiles] = React.useState<File[]>([]);
  const [filePrefix, setFilePrefix] = React.useState<string>('');
  const [pathS3KeySheetsResponse, setPathS3KeySheetsResponse] = React.useState<PathS3KeySheets[]>([]);
  const [selectedS3FileSheet, setSelectedS3FileSheet] = useState<string[]>([]);
  const [uploadResponse, setUploadResponse] = useState<any[]>([]);
  const [isUploading, setIsUploading] = useState(false as boolean);
  const [isGettingSheetNames, setIsGettingSheetNames] = useState(false as boolean);
  const [isUploadingToDB, setIsUploadingToDB] = useState(false as boolean);
  const [s3KeyToFileName, setS3KeyToFileName] = useState<Record<string, string>>({});

  const ControlledExpander = (props) => {
    const [open, setOpen] = useState(false);
    return <Expander open={open} onChange={setOpen} {...props} />;
  };
  useEffect(() => {
    if (files.length > 0) {
      setFilePrefix(
        BatchUploadDataViewConfig[props.dataType].s3Folder +
          props.projectName +
          '/' +
          props.userInfo.username +
          moment().utc().format('YYYYMMDD_HHmmss_SSS_'),
      );
    }
  }, [files, props.dataType, props.projectName, props.userInfo.username]);

  useEffect(() => {
    const newMapping = pathS3KeySheetsResponse.reduce((acc, item) => {
      acc[item.s3Key] = item.path;
      return acc;
    }, {});
    setS3KeyToFileName((prevMapping) => ({ ...prevMapping, ...newMapping }));
  }, [pathS3KeySheetsResponse]);
  /*
  const renderARCradleJobDetails = (cradleJob) => {
    if (!cradleJob) return null;

    const renderValue = (value) => {
      if (typeof value === 'object' && value !== null) {
        return (
          <ControlledExpander title="View Details" type="section">
            <pre>{JSON.stringify(value, null, 2)}</pre>
          </ControlledExpander>
        );
      }
      return <Text>{String(value)}</Text>;
    };

    return (
      <Box spacingInset="200" type="outline">
        <Heading level={6}>Cradle Job:</Heading>
        {Object.entries(cradleJob).map(([key, value]) => {
          if (value !== null && value !== undefined && value !== '') {
            return (
              <Box key={key}>
                <Text>{key}:</Text>
                {renderValue(value)}
              </Box>
            );
          }
          return null;
        })}
      </Box>
    );
  };
  */

  const renderSheetUploadResponseDetails = (details) => {
    if (!details) return null;

    let parsedDetails = details;
    if (typeof details === 'string') {
      try {
        parsedDetails = JSON.parse(details);
      } catch (e) {
        // If parsing fails, we'll use the original string
        return <Text>{details}</Text>;
      }
    }

    if (Array.isArray(parsedDetails)) {
      return (
        <Box>
          {parsedDetails.map((item, index) => (
            <Box key={index}>{renderObjectOrValue(item)}</Box>
          ))}
        </Box>
      );
    }

    if (typeof parsedDetails === 'object' && parsedDetails !== null) {
      return renderObjectOrValue(parsedDetails);
    }

    return <Text>{String(parsedDetails)}</Text>;
  };

  const renderObjectOrValue = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
      return <Text>{String(obj)}</Text>;
    }

    return (
      <Box>
        {Object.entries(obj).map(([key, value]) => (
          <Text key={key}>
            <strong>{key}:</strong> {formatValue(value)}
          </Text>
        ))}
      </Box>
    );
  };

  const formatValue = (value) => {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    if (Array.isArray(value)) {
      return value.map(formatValue).join(', ');
    }
    if (typeof value === 'object' && value !== null) {
      return Object.entries(value)
        .map(([k, v]) => `${k}: ${formatValue(v)}`)
        .join(', ');
    }
    return String(value);
  };

  const callDelegation = async (userMethodParams: any, successCallBack: Function, errorCallBack: Function) => {
    let auth = await getCurrentCredentials();
    var lambdaClient = new Lambda({
      region: 'us-east-1',
      credentials: auth,
    });
    console.log(
      JSON.stringify({
        function: 'callDelegation',
        auth: auth,
      }),
    );
    console.log(
      JSON.stringify({
        function: 'callDelegation',
        userMethodParams: userMethodParams,
      }),
    );
    var res = lambdaClient.invoke(
      {
        FunctionName: 'arn:aws:lambda:us-east-1:336473673731:function:IRISPythonLambdas-firebolt_delegation4',
        Payload: JSON.stringify(userMethodParams),
        InvocationType: 'Event',
      },
      (err, data) => {
        if (data && data.Payload && typeof data.Payload == 'string') {
          console.log('callDelegation callback');
          console.log(data.Payload);
          successCallBack(JSON.parse(data.Payload));
        } else if (err) {
          errorCallBack(true);
        }
      },
    );
    return res;
  };

  const callValidationIf = async (s3Key: string) => {
    if (props.projectName === 'SSD Weekly SnOP') {
      var datasetName = '';
      if (s3Key.includes('Planning_Assumptions')) {
        datasetName = 'Planning_Assumptions';
      }
      var params = {
        projectName: props.projectName,
        datasetName: datasetName,
        uploadKey: 'public/' + s3Key,
        requestUserId: props.userInfo.username,
        isDev: props.projectEnv === 'Dev',
        operation: 'Validation',
      };
      console.log('callDelegation params' + JSON.stringify(params));
      if (datasetName !== '') {
        callDelegation(
          params,
          () => {
            console.log('callDelegation Validation success');
          },
          () => {
            console.log('callDelegation Validation errored');
          },
        );
      }
    }
  };

  const subscribeToRefreshStatus = async (
    appSyncJobID: string,
    setDataFunction: Function,
    actionsAfterDataReceived: Function,
  ) => {
    const subscription = (
      graphQLClient.graphql({
        query: onCreateFireBoltStatusInfoAppSyncJobID,
        variables: {
          id: appSyncJobID,
        },
        authMode: 'userPool',
      }) as any
    ).subscribe({
      next: (data) => {
        console.log('data: ', data);
        console.log('Upload Results parsed:' + JSON.parse(data.data.onCreateFireBoltStatusInfoAppSyncJobID.results));
        setDataFunction(JSON.parse(data.data.onCreateFireBoltStatusInfoAppSyncJobID.results));
        subscription.unsubscribe();
        actionsAfterDataReceived();
      },
    });
    return subscription;
  };

  const handleFileAttached = (acceptedFiles: File[]) => {
    if (files?.length > 0) {
      const newFiles = [...files, ...acceptedFiles];
      const uniqueFiles = Array.from(new Set(newFiles.map((a) => a.path))).map(
        (path): File => newFiles.find((a) => a.path === path)!,
      );
      setFiles(uniqueFiles);
    } else {
      setFiles(acceptedFiles);
    }
    setUploadResponse([]);
  };

  const handleFilesSubmit = async () => {
    setIsGettingSheetNames(true);
    // If the file is selected , only then run the code...
    if (files.length === 0) {
      setIsUploading(false);
    } else {
      setIsUploading(true);
      const actionsAfterhandleFilesSubmit = () => {
        setFiles([]);
        setSelectedS3FileSheet([]);
        setIsUploading(false);
        setIsGettingSheetNames(false);
      };

      try {
        const inputPathS3Key: string[] = await Promise.all(
          files.map(async (file) => {
            const fileName = filePrefix + file.path.replace(/[(){}]/g, '_');

            try {
              const result = await uploadData({
                path: 'public/' + fileName,
                data: file as unknown as Blob,
                options: {
                  contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  onProgress: ({ transferredBytes, totalBytes }) => {
                    if (totalBytes) {
                      console.log(
                        `Upload progress for ${fileName}: ${Math.round((transferredBytes / totalBytes) * 100)}%`,
                      );
                    }
                  },
                },
              }).result;

              console.log('Path from Response:', result.path);
              return JSON.stringify({ path: file.path, s3Key: fileName });
            } catch (error) {
              console.log('Error uploading file:', error);
              throw error;
            }
          }),
        );
        var appSyncJobID =
          props.projectName +
          '-BatchUploadDataView-uploadinputdatafiles-getSheetNames-LambdaStatus' +
          props.userInfo.username +
          moment().valueOf().toString();
        var apiCallParam = {
          headers: {},
          queryStringParameters: {
            operation: 'getSheetNames',
            s3Key: inputPathS3Key,
            userId: props.userInfo.username,
            projectName: props.projectName,
            appSyncJobID: appSyncJobID,
          },
        };
        console.log(apiCallParam);

        let subscription;
        try {
          subscription = subscribeToRefreshStatus(
            appSyncJobID,
            setPathS3KeySheetsResponse,
            actionsAfterhandleFilesSubmit,
          ) as any;

          const response = await API.get<PathS3KeySheets[]>(
            'FireboltWebsiteAPIs',
            '/uploadinputdatafiles',
            apiCallParam,
          );
          console.log(apiCallParam);
          console.log(response);
          setPathS3KeySheetsResponse(response);
          actionsAfterhandleFilesSubmit();
          if (subscription && typeof subscription.unsubscribe === 'function') {
            subscription.unsubscribe();
          }
        } catch (err) {
          console.log('Getsheet Failed, wait for GraphQL Response', err);
          // Don't unsubscribe here, as we're still waiting for the GraphQL response
        }
      } catch (err) {
        console.log('Upload Failed', err);
      }
    }
  };

  const handleFilesSubmitToDB = async () => {
    setIsUploadingToDB(true);
    const selectedS3FileSheetJSON = selectedS3FileSheet.map((e) => JSON.parse(e));
    var appSyncJobID =
      props.projectName +
      '-BatchUploadDataView-uploadinputdatafiles-' +
      BatchUploadDataViewConfig[props.dataType].operation +
      '-LambdaStatus' +
      props.userInfo.username +
      moment().valueOf().toString();
    var apiCallParam = {
      headers: {},
      queryStringParameters: {
        operation: BatchUploadDataViewConfig[props.dataType].operation,
        s3Key: Array.from(new Set(selectedS3FileSheetJSON.map((e) => e.s3Key))),
        userId: props.userInfo.username,
        projectName: props.projectName,
        projectEnv: props.projectEnv,
        appSyncJobID: appSyncJobID,
      },
    };

    selectedS3FileSheet.forEach((e) => {
      const eData = JSON.parse(e);
      const spaceEncodedS3Key = encodeSpaces(eData.s3Key);
      if (apiCallParam.queryStringParameters[spaceEncodedS3Key])
        apiCallParam.queryStringParameters[spaceEncodedS3Key].push(eData.sheet);
      else apiCallParam.queryStringParameters[spaceEncodedS3Key] = [eData.sheet];
    });
    console.log(apiCallParam);
    setIsUploading(true);

    try {
      const actionsAfterhandleFilesSubmit = () => {
        console.log('selectedS3FileSheet ' + JSON.stringify(selectedS3FileSheet));
        let s3Keys = selectedS3FileSheet.map((e) => {
          const eData = JSON.parse(e);
          return eData.s3Key as string;
        });
        console.log('s3Keys ' + JSON.stringify(s3Keys));
        let uniqueS3Keys = new Set(s3Keys);
        let arrayS3Keys = Array.from(uniqueS3Keys);
        console.log('arrayS3Keys ' + JSON.stringify(arrayS3Keys));
        arrayS3Keys.forEach((k) => {
          callValidationIf(k);
        });
        setPathS3KeySheetsResponse([]);
        setSelectedS3FileSheet([]);
        setFiles([]);
        setIsUploading(false);
        setIsUploadingToDB(false);
      };
      subscribeToRefreshStatus(appSyncJobID, setUploadResponse, actionsAfterhandleFilesSubmit);
      await API.get<PathS3KeySheets[]>('FireboltWebsiteAPIs', '/uploadinputdatafiles', apiCallParam);
    } catch (err) {
      console.log('Upload Failed', err);
    }
    console.log(uploadResponse);
  };

  const getInfoLogPath = (sheet, fileName) =>
    `${fileName.replace('web-input-data/Data Repository/', 'public/web-input-data/Data Repository/logs/load_info/')}_${sheet.sheetName}.csv/`;
  const getExecutionLogPath = (sheet, fileName) =>
    `${fileName.replace('web-input-data/Data Repository/', 'public/web-input-data/Data Repository/logs/execution_info/')}_${sheet.sheetName}.csv/`;
  /*
  const renderUploadResponse = () => {
    if (uploadResponse.length === 0) return null;

    return (
      <Box type="outline" spacingInset="400" backgroundColor="subdued" overflowY="auto" maxHeight="600px">
        <Heading level={5}>Upload Results:</Heading>
        <Table headerRows={1}>
          <TableRow>
            <TableCell>File Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Details</TableCell>
          </TableRow>
          {uploadResponse.map((fileResult, index) => (
            <TableRow key={index}>
              <TableCell>{s3KeyToFileName[fileResult.fileName] || fileResult.fileName}</TableCell>
              <TableCell>{fileResult.status}</TableCell>
              <TableCell>
                <Button onClick={() => toggleDetailsRow(index)}>
                  {expandedRows.includes(index) ? 'Hide Details' : 'Show Details'}
                </Button>

                {expandedRows.includes(index) && (
                  <Box type="outline" spacingInset="300" backgroundColor="highlight" width="100%">
                    <Column spacing="400">
                      <Box spacingInset="200" type="outline">
                        <Heading level={6}>File Details:</Heading>
                        <Text>File Name: {s3KeyToFileName[fileResult.fileName] || fileResult.fileName}</Text>
                        <Text>S3 Key: {fileResult.fileName}</Text>
                      </Box>
                      <Table headerRows={1}>
                        <TableRow>
                          <TableCell>Sheet Name</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Details</TableCell>
                        </TableRow>
                        {fileResult.sheets.map((sheet, sheetIndex) => {
                          const infoLogPath = getInfoLogPath(sheet, fileResult.fileName);
                          const executionLogPath = getExecutionLogPath(sheet, fileResult.fileName);

                          return (
                            <TableRow key={sheetIndex}>
                              <TableCell>{sheet.sheetName}</TableCell>
                              <TableCell>{sheet.status}</TableCell>
                              <TableCell>
                                <Box spacingInset="200" type="outline">
                                  <Heading level={6}>Sheet Status</Heading>
                                  {renderSheetUploadResponseDetails(sheet.details)}
                                </Box>
                                {sheet.status !== 'Success' && props.projectName === 'Data Repository' && (
                                  <>
                                    <ControlledExpander title="Info Log" type="section">
                                      <Box spacingInset="200" type="outline">
                                        <DRLogReader path={infoLogPath} />
                                      </Box>
                                    </ControlledExpander>
                                    <ControlledExpander title="Execution Log" type="section">
                                      <Box spacingInset="200" type="outline">
                                        <DRLogReader path={executionLogPath} />
                                      </Box>
                                    </ControlledExpander>
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </Table>
                      {fileResult.cradleJob &&
                        props.projectName === 'Andes Repository' &&
                        renderARCradleJobDetails(fileResult.cradleJob)}

                      {fileResult.missingTablesInConfig && Object.keys(fileResult.missingTablesInConfig).length > 0 && (
                        <Box spacingInset="200" type="outline">
                          <Heading level={6}>Missing Tables in Config:</Heading>
                          <Table headerRows={1}>
                            <TableRow>
                              <TableCell>Table Name</TableCell>
                              <TableCell>Message</TableCell>
                            </TableRow>
                            {Object.entries(fileResult.missingTablesInConfig).map(([table, message], index) => (
                              <TableRow key={index}>
                                <TableCell>{table}</TableCell>
                                <TableCell>{String(message)}</TableCell>
                              </TableRow>
                            ))}
                          </Table>
                        </Box>
                      )}
                    </Column>
                  </Box>
                )}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Box>
    );
  };

  */

  const renderUploadResponse = () => {
    if (uploadResponse.length === 0) return null;

    return (
      <Box type="outline" spacingInset="400" backgroundColor="subdued" overflowY="auto" maxHeight="600px">
        <Heading level={5}>Upload Results:</Heading>
        {uploadResponse.map((fileResult: FileResult, index) => (
          <Box key={index} type="outline" spacingInset="300">
            <Heading level={6}>{s3KeyToFileName[fileResult.fileName] || fileResult.fileName}</Heading>
            <Text color={fileResult.status === 'Success' ? 'primary' : 'error'}>Status: {fileResult.status}</Text>

            {fileResult.sheets &&
              fileResult.sheets.map((sheet, sheetIndex) => (
                <Box key={sheetIndex} type="outline" spacingInset="200">
                  <Text type="b300">{sheet.sheetName}</Text>
                  <Text color={sheet.status === 'Success' ? 'primary' : 'error'}>Status: {sheet.status}</Text>
                  <ControlledExpander title="Sheet Details" type="section">
                    {renderSheetUploadResponseDetails(sheet.details)}
                  </ControlledExpander>
                  {sheet.status !== 'Success' && sheet.error && (
                    <Text color="error">Error: {JSON.stringify(sheet.error)}</Text>
                  )}
                  {(props.projectName === 'Andes Repository' || props.projectName === 'Data Repository') &&
                    sheet.status !== 'Success' && (
                      <>
                        <ControlledExpander title="Info Log" type="section">
                          <DRLogReader path={getInfoLogPath(sheet, fileResult.fileName)} />
                        </ControlledExpander>
                        <ControlledExpander title="Execution Log" type="section">
                          <DRLogReader path={getExecutionLogPath(sheet, fileResult.fileName)} />
                        </ControlledExpander>
                      </>
                    )}
                </Box>
              ))}

            {props.projectName === 'Data Repository' &&
              fileResult.successfulUploads &&
              Object.entries(fileResult.successfulUploads).map(([sheetName, uploads]) => (
                <Box key={sheetName} type="outline" spacingInset="200">
                  <Text type="b300">{sheetName} - Successful Uploads</Text>
                  {Array.isArray(uploads) ? (
                    uploads.map((upload: Upload, uploadIndex: number) => (
                      <Box key={uploadIndex} spacingInset="100">
                        {upload.db && <Text>Database: {upload.db}</Text>}
                        {upload.details && <Text>Details: {JSON.stringify(upload.details)}</Text>}
                        {upload.downstreamJobs && (
                          <ControlledExpander title="Downstream Jobs" type="section">
                            {renderDownstreamJobs(upload.downstreamJobs)}
                          </ControlledExpander>
                        )}
                      </Box>
                    ))
                  ) : (
                    <Box spacingInset="100">
                      {uploads && typeof uploads === 'object' && (
                        <>
                          {(uploads as Upload).db && <Text>Database: {(uploads as Upload).db}</Text>}
                          {(uploads as Upload).details && (
                            <Text>Details: {JSON.stringify((uploads as Upload).details)}</Text>
                          )}
                          {(uploads as Upload).downstreamJobs && (
                            <ControlledExpander title="Downstream Jobs" type="section">
                              {renderDownstreamJobs((uploads as Upload).downstreamJobs)}
                            </ControlledExpander>
                          )}
                        </>
                      )}
                      {typeof uploads !== 'object' && <Text>Upload information: {JSON.stringify(uploads)}</Text>}
                    </Box>
                  )}
                </Box>
              ))}

            {props.projectName === 'Data Repository' &&
              fileResult.missingTablesInConfig &&
              Object.entries(fileResult.missingTablesInConfig).length > 0 && (
                <Box type="outline" spacingInset="200">
                  <Heading level={6}>Missing Tables in Config</Heading>
                  {Object.entries(fileResult.missingTablesInConfig).map(([table, message], index) => (
                    <Text key={index}>{`${table}: ${message}`}</Text>
                  ))}
                </Box>
              )}

            {fileResult.errors && Object.entries(fileResult.errors).length > 0 && (
              <Box type="outline" spacingInset="200">
                <Heading level={6}>Errors</Heading>
                {Object.entries(fileResult.errors).map(([errorKey, errorValue], index) => (
                  <Text key={index} color="error">{`${errorKey}: ${JSON.stringify(errorValue)}`}</Text>
                ))}
              </Box>
            )}
          </Box>
        ))}
      </Box>
    );
  };

  const renderDownstreamJobs = (downstreamJobs) => {
    return (
      <>
        {downstreamJobs.Datanet && (
          <Box spacingInset="100">
            <Text type="b300">Datanet Jobs:</Text>
            {downstreamJobs.Datanet.map((job, index) => (
              <Text key={index}>
                Job {index + 1}: {job.status} - {job.message}
              </Text>
            ))}
          </Box>
        )}
        {downstreamJobs.Cradle && (
          <Box spacingInset="100">
            <Text type="b300">Cradle Jobs:</Text>
            {downstreamJobs.Cradle.map((job, index) => (
              <Text key={index}>
                Job {index + 1}: {job.status} - {job.message}
              </Text>
            ))}
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <Row width="100%" widths="fill">
        <Box type="outline" spacingInset="400" height="100%">
          <Heading level={5}>Select the Input Files:</Heading>
          <Column spacing="large">
            <FileInput onFileAttached={handleFileAttached} type="multiple" accept=".xlsx">
              {files?.map((file) => {
                return (
                  <FileDetails
                    error={file.error}
                    errorMessage={file.errorMessage}
                    file={file}
                    key={file.name}
                    onClickRemoveFile={() => {
                      const updatedFileArr = files.filter((fl) => fl.name !== file.name);
                      setFiles(updatedFileArr);
                    }}
                    uploadComplete={true}
                  />
                );
              })}
            </FileInput>
            <Row>
              <Button
                onClick={handleFilesSubmit}
                disabled={
                  !(files?.length > 0) ||
                  isUploading ||
                  (props.recalcInProgress !== undefined && props.recalcInProgress)
                }
              >
                Get Sheet Names
              </Button>
            </Row>
          </Column>
        </Box>
      </Row>
      <Row width="100%" widths="fill">
        {isGettingSheetNames ? (
          <Loader />
        ) : (
          <Box type="outline" spacingInset="400" height="100%">
            <Heading level={5}>Select Sheets to be Uploaded:</Heading>
            <ControlGroup onChange={setSelectedS3FileSheet} value={selectedS3FileSheet}>
              {pathS3KeySheetsResponse?.map((file) =>
                file.sheets?.map((sheet) => (
                  <Checkbox key={file.s3Key + sheet} value={JSON.stringify({ s3Key: file.s3Key, sheet: sheet })}>
                    <Row>
                      <Column>
                        <Text>{file.path}</Text>
                      </Column>
                      <Column>
                        <Text>{' -> '}</Text>
                      </Column>
                      <Column>
                        <Text>{sheet}</Text>
                      </Column>
                    </Row>
                  </Checkbox>
                )),
              )}
            </ControlGroup>
            <Row>
              <Button onClick={handleFilesSubmitToDB} disabled={!(selectedS3FileSheet?.length > 0) || isUploading}>
                Upload to Database
              </Button>
            </Row>
          </Box>
        )}
      </Row>
      <Row width="100%" widths="fill">
        {isUploadingToDB ? <Loader /> : renderUploadResponse()}
      </Row>
    </>
  );
};

/*

                      {fileResult.uploadDetails && (
                        <Box spacingInset="200" type="outline">
                          <Heading level={6}>Upload Details:</Heading>
                          <Text>Moment Key: {fileResult.uploadDetails.momentKey}</Text>
                          <Text>Dimension List Updated: {fileResult.uploadDetails.dimListUpdated ? 'Yes' : 'No'}</Text>
                          <Text>
                            Upload Times Updated: {fileResult.uploadDetails.uploadTimesUpdated ? 'Yes' : 'No'}
                          </Text>
                        </Box>
                      )}
                      {fileResult.recalculationTriggered !== undefined && (
                        <Box spacingInset="200" type="outline">
                          <Heading level={6}>Recalculation:</Heading>
                          <Text>Triggered: {fileResult.recalculationTriggered ? 'Yes' : 'No'}</Text>
                        </Box>
                      )}
*/
